import service from '@/utils/request';

// @Tags DdpDealer
// @Summary 创建DdpDealer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealer true "创建DdpDealer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealer/createDdpDealer [post]
export var createDdpDealer = function createDdpDealer(data) {
  return service({
    url: "/ddpDealer/createDdpDealer",
    method: 'post',
    data: data
  });
};

// @Tags DdpDealer
// @Summary 删除DdpDealer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealer true "删除DdpDealer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealer/deleteDdpDealer [delete]
export var deleteDdpDealer = function deleteDdpDealer(data) {
  return service({
    url: "/ddpDealer/deleteDdpDealer",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealer
// @Summary 删除DdpDealer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除DdpDealer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealer/deleteDdpDealer [delete]
export var deleteDdpDealerByIds = function deleteDdpDealerByIds(data) {
  return service({
    url: "/ddpDealer/deleteDdpDealerByIds",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealer
// @Summary 更新DdpDealer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealer true "更新DdpDealer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /ddpDealer/updateDdpDealer [put]
export var updateDdpDealer = function updateDdpDealer(data) {
  return service({
    url: "/ddpDealer/updateDdpDealer",
    method: 'put',
    data: data
  });
};

// @Tags DdpDealer
// @Summary 用id查询DdpDealer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealer true "用id查询DdpDealer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /ddpDealer/findDdpDealer [get]
export var findDdpDealer = function findDdpDealer(params) {
  return service({
    url: "/ddpDealer/findDdpDealer",
    method: 'get',
    params: params
  });
};

// @Tags DdpDealer
// @Summary 分页获取DdpDealer列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取DdpDealer列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealer/getDdpDealerList [get]
export var getDdpDealerList = function getDdpDealerList(params) {
  return service({
    url: "/ddpDealer/getDdpDealerList",
    method: 'get',
    params: params
  });
};